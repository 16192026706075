@import '../../assets/styles/variables';

@mixin breakpoint($point) {
  @if $point ==md {

    // 768px
    @media (min-width: 48em) {
      @content;
    }
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background: rgb(00, 0, 0, 0.52);

  @include breakpoint(md) {
    padding: 0 3rem;
  }

  transition: 0.3s ease all;

  &__content {
    overflow: hidden;
    color: #fff;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    background: transparent;
    display: flex;
    padding: 0 3rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;

    &__left {
      display: flex;
      align-items: center;
      height: 60px;
    }

    &__right {
      display: flex;
      align-items: center;
      padding-right: 30px;
    }

    &__logo {
      font-size: 2rem;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
      display: flex;

      &__img {
        width: 100px;
        height: auto;
      }
    }

    &__mainmenu {
      position: relative;
      margin-right: 120px;
      top: -10px;

      .fa_icon {
        color: white;
      }

      .dd-wrapper {
        position: fixed;
        cursor: pointer;
        text-align: left;

        .dd-header {
          color: white;

          &:hover {
            color: $main-btn-color;
            cursor: pointer;

            .fa_icon {
              color: $main-btn-color;
            }
          }
        }

        .dd-list {
          margin: 0;
          margin-top: 15px;
          padding: 4px;
          color: black;
          text-align: left;
          background: white;
          border-radius: 4px;
          transition: all 2s ease-in;

          li {
            padding: 4px 5px;
            list-style-type: none;

            &:hover {
              color: $main-btn-color
            }
          }


        }
      }
    }

    &__signin_btn {
      color: white;
      margin-right: 20px;

      & .fa_icon {
        margin-right: 10px;
      }

      &:hover {
        & svg {
          color: $main-btn-hover-color;
        }

        color:$main-btn-hover-color ;
      }
    }

    &__right__btn {
      box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 20%);
      float: right;
      padding: 8px 24px;
      position: relative;
      border-radius: 30px;
      color: #fff;
      font-size: 12px;
      text-decoration: none;
      background: $main-btn-color;

      &:hover {
        box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 10%);
      }
    }


    &__nav {
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba(rgb(32, 32, 32), 0.9);
      backdrop-filter: blur(2px);
      transform: translate(0);
      transition: 0.3s ease transform;


      @include breakpoint(md) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-top: auto;

        @include breakpoint(md) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          margin-right: calc(0.5rem + #{16px});
        }

        li {
          &:not(:last-child) {
            margin-bottom: 32px;

            @include breakpoint(md) {
              margin-bottom: 0;
              margin-right: 16px;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
            padding: 0.75rem 1.25rem;
            border-radius: 12px;
            transition: 0.3s ease all;

            &:hover {
              color: $main-tab-color;

              & * {
                color: $main-tab-color;
              }
            }

            & svg {
              margin-right: 5px;
            }

          }
        }
      }

      .btn {
        cursor: pointer;
        outline: none;
        padding: 0.75rem 1.25rem;
        border-radius: 12px;
        font-size: 1rem;
        font-family: inherit;
        background: transparent;
        color: #fff;
        border: 1px solid transparent;
        transition: 0.3s ease all;
        margin: 0.50em 0.50em;


        &:hover {
          background: rgba(rgb(162, 162, 246), 0.1);
          color: rgb(162, 162, 246);
        }

        &:active {
          border-color: rgb(162, 162, 246);
          background: linear-gradient(rgba(rgb(162, 162, 246), 0.2), rgba(rgb(162, 162, 246), 0.3));
          color: rgb(162, 162, 246);
        }

        &__login {

          background-color: rgb(32, 32, 32);
          color: #fff;

        }

      }


      &.isMenu {
        transform: translate(100%);
      }
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: 0.3s ease all;
      position: relative;

      &:hover {
        color: rgb(162, 162, 246);
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}