@import '../../assets/styles/variables';

.google-box {
  width: 100%;
  padding-top: 60px;
  background: #444444;

  .google-box-map {
    height: 100vh;
  }
}

.popup-btn {
  position: fixed;
  top: 40%;
  right: 20px;
  // width: 40px;
  // height: 40px;
  padding: 15px;
  align-items: center;
  border-radius: 50%;
  background: $main-btn-color;
  color: white;

  & .fa_icon {
    margin: auto;
  }

  &:hover {
    background: transparent;

    svg {
      color: $main-btn-color;
    }
  }
}

.map_control_panel {
  position: absolute;
  right: 30px;
  top: 30%;

  .google_map_panel_btn {

    border-radius: 50%;
    padding: 10px;
    border: 3px solid white;
    // box-shadow: 0px 0px 0px 7px rgb(255,255,255,0.2);
    background: $main-btn-color;
    margin-top: 20px;
    text-align: center;

    .fa_icon {}

    &:hover {
      color: $main-btn-color;
      opacity: 0.4;
      cursor: pointer;
    }
  }
}