@import "../../assets/styles/variables";

.header-searchbar {
  position: relative;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;

  & .header-search-input-item {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    background: #fff;
    height: 30px;
    width: 120px;

    & input {
      border: none;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      float: left;
      text-indent: 20px;
      outline: none;
    }
  }

  & .header-search-btn {
    height: 30px;
    border: none;
    color: #fff;
    background: $search-btn-bg;
    line-height: 30px;
    border-radius: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 0 20px;
    cursor: pointer;
  }
}