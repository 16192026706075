@import '../../assets/styles/variables';

.footer {
  position: fixed;
  width: calc(100% - 56px);
  height: 350px;
  left: 50%;
  translate: -50%;
  max-width: 1440px;
  background-color: hsla(0, 0%, 100%, .9);
  padding: 20px;
  padding-top: 0px;
  transition: all 0.5s ease-in-out;
  border-radius: 20px;
  border: 2px solid $main-btn-color;

  &.hide {
    bottom: -330px;
  }

  &.display {
    bottom: 20px;
  }

  &_top_toggle {
    width: 100%;
    padding-top: 20px;
    border: none;
    height: 30px;
    text-align: center;

    .fa_icon {
      color: rgb(0, 0, 0, 0.5);
    }
  }

  &_control {
    position: absolute;
    top: 15px;
    margin-left: 20px;

    .fa_icon {
      color: $main-btn-color;
    }

    & h5 {
      font-size: 18px;
      margin: 0px;
    }

  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    & .infoes_form {
      width: 220px;

      // border: 1px solid black;
      & .info_form {
        margin-top: 10px;
        margin-bottom: 20px;

        .info_title {
          font-style: italic;
          opacity: 0.7;
          font-size: 14px;
          margin-bottom: 4px;
        }

        .info_input {
          min-height: 25px;
          font-size: 16px;
          outline: none;
          border: none;
          border-bottom: 1px solid #96dbfa;
          background: transparent;
          transition: all 0.5s ease-in-out;

          &:hover,
          :focus {
            border-bottom: 1px solid #96dbfa;
            transition: all 0.5s ease-in-out;
          }
        }

        .btn {
          box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 20%);
          float: right;
          padding: 13px 32px;
          position: relative;
          border-radius: 30px;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
          text-decoration: none;
          background: $main-btn-color;
          border: 2px solid $main-btn-hover-color;

          &:hover {
            box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 10%);
          }
        }

      }
    }

    & .items_form {
      width: 350px;
      display: flex;
      position: relative;

      & .item_infoes {
        width: 40%;
        margin: 10px;

        & .info {
          margin-bottom: 10px;

          .label {
            font-style: italic;
            opacity: 0.7;
            font-size: 14px;
            margin-bottom: 4px;
          }

          .info_input {
            width: 100%;
            min-height: 25px;
            font-size: 16px;
            outline: none;
            border: none;
            border-bottom: 1px solid #96dbfa;
            background: transparent;
            transition: all 0.5s ease-in-out;

            &:hover,
            :focus {
              border-bottom: 1px solid #96dbfa;
              transition: all 0.5s ease-in-out;
            }

            &.button {
              padding: 8px 20px;
              position: relative;
              color: #fff;
              font-weight: 700;
              margin: auto;
              font-size: 14px;
              text-decoration: none;
              background: $main-btn-color;
              outline: none;
              border: 1px solid white;
              border-radius: 4px;

            }
          }
        }
      }

      & .colors_div {
        width: 60%;
        height: 270px;
        margin: 10px;

        .label {
          font-style: italic;
          opacity: 0.7;
          font-size: 14px;
          margin-bottom: 4px;
        }

        & .colors {
          display: flex;
          flex-direction: column;
          border: 1px solid $main-btn-color;
          padding: 4px;
          border-radius: 8px;

          .color_row {
            display: flex;
            justify-content: space-between;
            padding-bottom: 5px;

            .color {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      & .btn_bar {
        position: absolute;
        bottom: 5px;
        left: 50%;
        translate: -50%;

        button {
          padding: 8px 20px;
          position: relative;
          color: #fff;
          font-weight: 700;
          margin: auto;
          font-size: 14px;
          text-decoration: none;
          background: $main-btn-color;
          outline: none;
          border: 1px solid white;
          border-radius: 4px;
        }
      }
    }

    & .itemlist_form {
      width: 300px;
      padding: 10px;
      padding: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .item_header {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 5px;
        justify-content: space-between;
        color: black;
        font-style: italic;
        font-size: 14px;
        border-bottom: 1px solid $main-btn-color;

      }

      .itemlist {
        margin-top: 30px;
        overflow-y: scroll;
        overflow-x: none;
        position: absolute;
        right: -10px;
        width: 100%;
        max-height: 200px;

        .item {
          display: flex;
          padding: 8px 10px 8px 10px;
          margin-bottom: 5px;
          justify-content: space-between;
          color: white;
          text-align: center;
        }
      }

      .piecreate {
        width: 100%;
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        & button {
          padding: 8px 20px;
          position: relative;
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          text-decoration: none;
          background: $main-btn-color;
          outline: none;
          border: 1px solid white;
          border-radius: 4px;

          &:hover {
            box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 10%);
          }

          &.hidden {
            display: none;
          }
        }
      }
    }

    & .preview_form {
      width: 280px;
      height: 280px;
      display: grid;
      grid-template-rows: auto;
      justify-items: center;
      align-items: center;
      text-align: center;
      position: relative; 
      .image_preview {
        position: absolute;
        width: 250px;
        opacity: 0.8;
      }

      .preview_title {
        position: absolute;
        top: -70px;
        left: -20px;
        color: $main-btn-color;
        font-size: 20px;
        z-index: 10;
      }
      .preivew_tower_name{
        position: absolute;
        top: -10px;
        right: 10px;
        font-size: 22px;
        font-weight: 600;
        font-family: 'Times New Roman', Times, serif;
      }

      canvas {
        display: block;
      }

      .dir {
        position: absolute;
        width: 65px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -10;
      }

      span {
        font-size: 28px;
        font-weight: 600;
        font-family: 'Times New Roman', Times, serif;
        display: flex;
        font-stretch: 150%;
        margin-top: 26px;
        color: #444444;
        z-index: 11;
      }

      .dir.tt {
        top: -5px;
        left: 50%;
        background-image: url("../../assets/images/arrow.png");
        width: 20px;
        height: 30px;
        transform: rotate(-180deg);
        translate: -50%;
      }

      .dir.lc {
        top: calc(50% - 12px);
        left: 0px;
        background-image: url("../../assets/images/arrow.png");
        width: 20px;
        height: 30px;
        transform: rotate(90deg);

        span {
          transform: rotate(-90deg);
          margin-top: 34px;
        }
      }

      .dir.rc {
        top: calc(50% - 12px);
        right: 0px;
        background-image: url("../../assets/images/arrow.png");
        width: 20px;
        height: 30px;
        transform: rotate(-90deg);

        span {
          transform: rotate(90deg);
        }
      }

      .dir.bb {
        left: 50%;
        bottom: -5px;
        background-image: url("../../assets/images/arrow.png");
        width: 20px;
        height: 30px;
        translate: -50%;
      }

    }
  }

  & .border-div {
    width: 0px;
    border-right: 1px solid $main-btn-color;
    opacity: 0.7;
  }
  & .space-div{
    min-width: 40px;
  }

  .canvas.marker {
    background-color: #96dbfa;
  }

  .pie_chart{
    position: relative;
    .span{
      position: absolute;
      text-align: center;
      font-family: 'Times New Roman', Times, serif;
      white-space:nowrap;
      color: white;
    }
    .canvas-on-map {
      position: absolute;
      color: #96dbfa;
      top: -50px;
    }
  }
  
}