@import '../../assets/styles/variables';


.modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid $main-btn-color;
  border-radius: 10px;
  width: 200px;
  height: 80px;
  background: white;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;

  button {
    padding: 8px 20px;
    color: #fff;
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    text-decoration: none;
    background: $main-btn-color;
    outline: none;
    border: 1px solid white;
    border-radius: 4px;
  }
}